







import { defineComponent } from '@vue/composition-api'
import KText from '../typography/KText.vue'
import KLabel from './KLabel.vue'

export default defineComponent({
    components: { KText, KLabel },
    name: 'KBaseInput',
    setup() {
        //

        return {
            //
        }
    }
})
