




import { defineComponent } from '@vue/composition-api'
import KText from '../typography/KText.vue'

export default defineComponent({
    components: { KText },
    name: 'KLabel',
    props: {
        'label': {
            type: String,
            required: true
        }
    },
    setup() {
        //

        return {
            //
        }
    }
})
