













































import {
  computed,
  defineComponent,
  onMounted,
  Ref
} from '@vue/composition-api';
import KBaseInput from './KBaseInput.vue';
import useInputStyles from '@/@core/styles/inputStyles';
import useInputRules from '@/@core/helpers/inputRules';

export default defineComponent({
  components: { KBaseInput },
  name: 'KTagInput',
  setup(props, { attrs }) {
    const { label, rules, value, ...filteredAttrs } = attrs;

    const formattedRules: Ref<any[]> = computed(() =>
      rules ? useInputRules(rules as any[], attrs.value) : []
    );

    onMounted(() => {
      // @ts-ignore
      if (attrs.value?.length) attrs.value = [];
    });

    const addTag = (event: any) => {
      const isCommaPressed = event.target.value.indexOf(',') > 0;
      const isEnterPressed = event.which == 13;

      const isComaOrEnterPressed = isCommaPressed || isEnterPressed;

      if (isComaOrEnterPressed) {
        event.preventDefault();
        let val = event.target.value.replace(/,/g, '').trim();

        if (val.length > 0) {
          (attrs.value as string[]).push(val);
          event.target.value = '';
        }
      }
    };

    const removeTag = (index: number) =>
      (attrs.value as string[]).splice(index, 1);

    const removeLastTag = (event: any) => {
      const isValueEmpty = event.target.value.length == 0;
      if (isValueEmpty)
        removeTag((attrs.value as string[]).length - 1);
    };

    return {
      useInputStyles,
      filteredAttrs,
      formattedRules,
      addTag,
      removeTag,
      removeLastTag
    };
  }
});
